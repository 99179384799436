.sun-editor {
  width: auto;
  height: auto;
  box-sizing: border-box;
  font-family: Helvetica Neue;
  border: 1px solid #dadada;
  background-color: #fff;
  color: #000;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sun-editor .se-btn {
  float: left;
  width: 16px;
  height: 16px;
  border: 0;
  border-radius: 4px;
  margin: 5px !important;
  padding: 0;
  font-size: 12px;
  line-height: 27px;
}
